import React from "react";
import AccordionItem from "./AccordionItem";
import { APP_NAME } from "../config/constants";
import { Link } from "gatsby";
import BlogHome from "./BlogHome";
import BlogDetails from "./BlogDetails";

const Blog = (props) => {
  if(props.id === 'list') {
    return (
      <>
       <BlogHome />
      </>
    )
  }else{
    return (
      <>
      <BlogDetails {...props} />
     </>
    )
  } 
};
export default Blog;

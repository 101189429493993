import React from "react";
import Layout from "../components/Layout";
import NavOne from "../components/NavOne";
import NavTwo from "../components/NavTwo";
import Footer from "../components/Footer";
import Banner from "../components/Banner";
import Blog from "../components/Blog";
import Video from "../components/Video";
import CallToAction from "../components/CallToAction";
import CallToActionTwo from "../components/CallToActionTwo";
import Pricing from "../components/Pricing";
import Testimonial from "../components/Testimonial";
import Brands from "../components/Brands";
import Counter from "../components/Counter";
import Screenshots from "../components/Screenshots";
import Faq from "../components/Faq";
import NewsHome from "../components/NewsHome";
import { Router } from "@reach/router"
import Subscribe from "../components/Subscribe";
import { APP_NAME } from "../config/constants";
import About from "../components/About";
import BlogHome from "../components/BlogHome";

const ArticlesPage = () => (
  <Layout pageTitle={`Blog | ${APP_NAME}`}>
    <NavTwo />
    <div className="thm-gray-bg111">
      <BlogHome />
      <Subscribe />
      <Footer />
    </div>
  </Layout>
)

export default ArticlesPage;
